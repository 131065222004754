import React from 'react';
import Topbar from './topbar';
import Homepage from './homepage';
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme';

export default function App() {
	return <div className="App">
		<ThemeProvider theme={theme}>
			<Topbar />
			<Homepage />
		</ThemeProvider>
	</div>
}
