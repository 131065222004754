import { Typography } from '@mui/material'
import React from 'react'
import TextTransition, { presets } from "react-text-transition";

const TEXTS = [
    "नमस्ते",
    "नमस्कार",
    "নমস্কার",
    "ਸਤਿ ਸ਼੍ਰੀ ਅਕਾਲ",
    "வணக்கம்",
    "నమస్కరం",
    "నమస్కారం",
    "ನಮಸ್ತೆ",
    "ନମସ୍କାର",
    "કેમ છો"
];

export default function ScrollingGreeting() {
    const [index, setIndex] = React.useState(0);


    React.useEffect(() => {
        const intervalId = setInterval(() =>
            setIndex(index => index + 1),
            3000 // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);


    return <Typography
        variant="h5"
        color="textPrimary"
        sx={{ marginLeft: 2, display: 'inline', mt: '20px' }}
    >
        <TextTransition springConfig={presets.wobbly} direction="up">
            {TEXTS[index % TEXTS.length]}
        </TextTransition>
    </Typography >
}