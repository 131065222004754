import { Box } from '@mui/material'
import React from 'react'
import NameSection from '../name_section'
import Footer from '../footer'
import Coding from './coding'
import './snap.css'
import Travelling from './travelling'
import Shooting from './shooting'


export default function Homepage() {

    return <Box className="snap-container">
        <NameSection />
        <Coding />
        {/* <Travelling />
        <Shooting /> */}
        <Footer />
    </Box>
}
