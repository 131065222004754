import { Box, Grid, useMediaQuery } from '@mui/material'
import React from 'react'
import PhotoGallery from './photo_gallery'
import '../homepage/snap.css'

export default function ShowoffSection(props) {
    const largeScreen = useMediaQuery(theme => theme.breakpoints.up('md'))

    const textSection = <Grid
        container
        sx={{
            width: largeScreen ? '50%' : '100%'
        }}
    >
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            {props.infoContent}
        </Grid>
    </Grid>

    const imageSection = <Grid
        container
        sx={{
            width: largeScreen ? '50%' : '100%',
        }}
        direction="column"
        justifyContent="space-between"
        alignItems="center"
    >
        <Box sx={{ width: '100%', height: '60px' }} />
        {props.imagesHeaders}
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
        >
            <PhotoGallery
                imageOne={props.imageOne}
                imageTwo={props.imageTwo}
                imageThree={props.imageThree}
            />
        </Grid>
    </Grid>

    const firstSection = () => {
        if (props.reverse) {
            return imageSection
        } else {
            return textSection
        }
    }
    const secondSection = () => {
        if (props.reverse) {
            return textSection
        } else {
            return imageSection
        }
    }

    return <Grid
        container
        spacing={0}
        sx={{
            minHeight: '100vh',
            p: '20px', pt: '40px'
        }}
        className='snap-child'
    >
        <Grid
            container
            spacing={2}
            direction={{ xs: "row-reverse", md: "row" }}
        >
            {firstSection()}
            {secondSection()}

        </Grid>
    </Grid>
}