import { LinkedIn, Twitter, Email } from '@mui/icons-material'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import '../homepage/snap.css'

export default function Footer() {
    const buttons = [
        {
            name: 'LinkedIn',
            action: () => {
                window.open("https://www.linkedin.com/in/sgkandale/", "_blank", "noreferrer")
            },
            icon: <LinkedIn fontSize='large' />
        },
        {
            name: 'Twitter',
            action: () => {
                window.open("https://twitter.com/SGKandale", "_blank", "noreferrer")
            },
            icon: <Twitter fontSize='large' />
        },
        {
            name: 'Mail',
            action: () => {
                window.open("mailto:me@sgkandale.com", "", "noreferrer")
            },
            icon: <Email fontSize='large' />
        }
    ]
    return <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{ bgcolor: 'rgb(52, 52, 52)' }}
    >
        <Box sx={{ width: '100%', height: '30px' }} />
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ paddingY: 7 }}
            id="contact"
            className='snap-child'
        >
            <Typography
                variant="h5"
                color="textSecondary"
            >
                Get in touch in with me
            </Typography>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                // spacing={5}
                sx={{ mt: 1 }}
            >
                {
                    buttons.map((eachButton) => {
                        return <IconButton
                            sx={{
                                color: 'text.primary'
                            }}
                            key={eachButton.name}
                            onClick={eachButton.action}
                        >
                            {eachButton.icon}
                        </IconButton>
                    })
                }
            </Grid>
        </Grid>
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ height: '30px', px: 2 }}
        >
            <Typography variant="captions">
                Made by me only :)
            </Typography>
        </Grid>
    </Grid>
}