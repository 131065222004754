import { Grid, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import GreetingHand from './greeting_hand'
import ScrollingGreeting from './scrolling_greeting'
import no_bg from '../statics/no_bg.png'
import '../homepage/snap.css'

export default function NameSection() {
    const largeScreen = useMediaQuery(theme => theme.breakpoints.up('md'))

    return <Grid
        container
        spacing={0}
        sx={{
            height: '100vh',
            minHeight: '600px',
            scrollSnapAlign: 'start',
            overflowY: 'hidden'
        }}
        className='snap-child'
    >
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            xs={12} md={6}
            sx={{
                padding: 8,
                // height: `calc(100vh - 80px)`,
            }}
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                    ml: '-10px'
                }}
            >
                <GreetingHand />
                <ScrollingGreeting />
            </Grid>
            <Typography variant="h6" color="textPrimary" align="left" sx={{ mt: '15px', mb: '-5px' }}>
                I am
            </Typography>
            <Typography
                variant="h3"
                color="textPrimary"
                align="left"
                sx={{
                    mb: '15px',
                    lineHeight: '45px',
                    mt: '8px',
                    ml: '-5px',
                    fontSize: {
                        xs: 36,
                        md: 42
                    }
                }}
            >
                Shantanu Kandale
            </Typography>
        </Grid>
        <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="center"
            xs={12} md={6}
        >
            <img
                src={no_bg}
                alt="avatar"
                style={{
                    height: largeScreen ? "500px" : "300px",
                    width: largeScreen ? "500px" : "300px",
                    filter: 'drop-shadow(0px 0px 15px #fff)'
                }}
            />
        </Grid>
    </Grid>
}