import { Typography, Link, Box } from '@mui/material'
import React from 'react'
import ShowoffSection from '../showoff_section'

export default function Coding() {
    return <ShowoffSection
        infoContent={
            <Box
                sx={{
                    width: {
                        xs: '100%',
                        md: '85%'
                    }
                }}
            >
                <Typography
                    variant="h5"
                    color="textPrimary"
                >
                    I'm a Backend Engineer by profession.
                </Typography>
                <Typography
                    variant="body1"
                    color="textPrimary"
                    sx={{
                        mt: 1
                    }}
                >
                    Primarily, I code in Go.
                    I have built scalable systems in Go.
                    <br />
                    Apart from that, I also know React JS.
                    It has been my go to tool for Web Development for about 3 years now.
                </Typography>
            </Box>
        }
        imagesHeaders={
            <Typography variant="h5" color="textPrimary">
                See me coding &nbsp;
                <Link href="https://github.com/sgkandale/" sx={{ textDecoration: 'none' }}>
                    here
                </Link>
            </Typography>
        }
        imageOne={{
            imageTitle: "Garbage LB",
            imageLink: "https://raw.githubusercontent.com/sgkandale/garbage-lb/main/ui_src/src/statics/logo.png",
            cardLink: "https://github.com/sgkandale/garbage-lb"
        }}
        imageTwo={{
            imageTitle: "Pingmaster",
            imageLink: "https://raw.githubusercontent.com/sgkandale/pingmaster/main/pingmaster.png",
            cardLink: "https://github.com/sgkandale/pingmaster"
        }}
        imageThree={{
            imageTitle: "Pingmaster UI",
            imageLink: "https://raw.githubusercontent.com/sgkandale/pingmaster/main/pingmaster.png",
            cardLink: "https://github.com/sgkandale/pingmaster_ui"
        }}
    />
}