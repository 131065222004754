import React from 'react'
import './greeting_hand.css'

export default function GreetingHand() {
    return <span
        className="greeting_hand"
        role="img"
        aria-label="greeting_hand-hand"
        style={{
            fontSize: '250%'
        }}
    >
        &#128591;
    </span>
}