import { Grid } from '@mui/material'
import React from 'react'
import PhotoCard from './photo_card'

export default function PhotoGallery(props) {
    return <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ pb: '30px' }}
    >
        <PhotoCard
            index={1}
            imageTitle={props.imageOne.imageTitle}
            imageLink={props.imageOne.imageLink}
            cardLink={props.imageOne.cardLink}
        />
        <PhotoCard
            index={2}
            imageTitle={props.imageTwo.imageTitle}
            imageLink={props.imageTwo.imageLink}
            cardLink={props.imageTwo.cardLink}
        />
        <PhotoCard
            index={3}
            imageTitle={props.imageThree.imageTitle}
            imageLink={props.imageThree.imageLink}
            cardLink={props.imageThree.cardLink}
        />
    </Grid>
}