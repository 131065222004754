import * as React from 'react';
import { AppBar, Link, useScrollTrigger, CssBaseline } from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
        background: trigger ? 'white' : 'transparent',
        // background: trigger ? 'linear-gradient(300deg, #020024, #151430, #0c1e39, #280730, #02272f)' : 'transparent',
        backgroundSize: '300% 300%',
        zIndex: 10
    });
}

function Topbar(props) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const topbarButtions = [
        {
            title: 'Contact Me',
            action: () => { },
            target: "#contact",
        }
    ];
    const title = "sgkandale"

    return <>
        <CssBaseline />
        <ElevationScroll {...props}>
            <AppBar position="fixed" sx={{ background: 'inherit' }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontWeight: 700,
                                color: 'inherit',
                                textDecoration: 'none',
                                textTransform: 'none'
                            }}
                        >
                            {title}
                        </Typography>
                        <Box sx={{
                            flexGrow: 1,
                            display: {
                                xs: 'flex', md: 'none'
                            },
                        }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {
                                        xs: 'block',
                                        md: 'none',
                                    },
                                }}
                            >
                                {
                                    topbarButtions.map((eachButton) => (
                                        <Link
                                            href={eachButton.target}
                                            key={eachButton.title}
                                            sx={{
                                                textDecoration: 'none',
                                            }}
                                        >
                                            <MenuItem>
                                                <Typography textAlign="center" sx={{ textTransform: 'none' }}>
                                                    {eachButton.title}
                                                </Typography>
                                            </MenuItem>
                                        </Link>
                                    ))
                                }
                            </Menu>
                        </Box>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontWeight: 700,
                                color: 'inherit',
                                textDecoration: 'none',
                                textTransform: 'none'
                            }}
                        >
                            {title}
                        </Typography>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: "flex-end", flexGrow: 1 }}>
                            {
                                topbarButtions.map((eachButton) => (
                                    <Link
                                        href={eachButton.target}
                                        key={eachButton.title}
                                        sx={{
                                            textDecoration: 'none'
                                        }}
                                    >
                                        <Button
                                            sx={{
                                                my: 2,
                                                color: 'white',
                                                display: 'block',
                                                textTransform: 'none'
                                            }}
                                        >
                                            {eachButton.title}
                                        </Button>
                                    </Link>
                                ))
                            }
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </ElevationScroll>
    </>
}
export default Topbar;
