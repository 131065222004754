import { CardMedia, Card, CardContent, Typography, useMediaQuery, Link } from '@mui/material'
import React from 'react'

export default function PhotoCard(props) {
    const largeScreen = useMediaQuery(theme => theme.breakpoints.up('md'))

    return <Card
        sx={{
            bgcolor: 'white',
            padding: largeScreen ? "20px" : "10px",
            ml: props.index === 3 ? "-100px" : 0,
            mr: props.index === 1 ? "-100px" : 0,
            zIndex: props.index === 2 ? 5 : 0,
            transform: props.index === 1 ? 'rotate(-10deg)' : props.index === 3 ? 'rotate(10deg)' : 'rotate(0deg)',
            textDecoration: 'none'
        }}
        component={Link}
        href={props.cardLink}
        target="_blank"
        elevation={3}
    >
        <CardContent>
            <Typography variant="body1" color="black">
                {props.imageTitle}
            </Typography>
        </CardContent>
        <CardMedia
            component="img"
            height={largeScreen ? "250" : "170"}
            width={largeScreen ? "250" : "170"}
            image={props.imageLink}
            alt="green iguana"
            sx={{
                objectFit: 'scale-down',
                height: largeScreen ? "250px" : "170px",
                width: largeScreen ? "250px" : "170px",
                border: '1px solid',
                borderColor: '#DADADA',
                borderRadius: '3px'
            }}
        />
    </Card>
}